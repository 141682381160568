import React, { useState, useEffect, useRef } from "react";

import "./Header.css"; // Import file CSS
import avatar1 from "../../assets/avatar1.jpeg";
import avatar2 from "../../assets/avatar2.jpeg";
import avatar3 from "../../assets/avatar3.jpeg";
import avatar4 from "../../assets/avatar4.jpeg";
import avatar5 from "../../assets/avatar5.jpeg";
import avatar6 from "../../assets/avatar6.jpeg";
import avatar7 from "../../assets/avatar7.jpeg";
import avatar8 from "../../assets/avatar8.jpeg";

const Header = () => {
  const handleButtonClick = () => {
    if (window.fbq) {
      window.fbq("track", "ButtonClick");
    }
    window.location.href = "https://url10.top/j/UARHtsil1780";
  };
  const [notification, setNotification] = useState(null);
  const indexRef = useRef(0); // Lưu trữ index

  useEffect(() => {
    const users = [
      { name: "Lestari", avatar: avatar1 },
      { name: "Marshanda", avatar: avatar2 },
      { name: "Murni", avatar: avatar3 },
      { name: "Adinda", avatar: avatar4 },
      { name: "Bagaskoro", avatar: avatar5 },
      { name: "Banyu", avatar: avatar6 },
      { name: "Agung", avatar: avatar7 },
      { name: "Mentari", avatar: avatar8 },
    ];

    const joinSimulation = setInterval(() => {
      const user = users[indexRef.current % users.length]; // Chọn người từ danh sách
      setNotification(user); // Hiển thị thông báo

      setTimeout(() => {
        setNotification(null); // Ẩn thông báo sau 3 giây
      }, 3000);

      indexRef.current++; // Tăng index
    }, 5000); // Hiển thị thông báo mỗi 5 giây

    return () => clearInterval(joinSimulation); // Dọn dẹp interval khi component bị unmount
  }, []); // Chỉ chạy khi component mount lần đầu

  return (
    <div>
      {notification && (
        <div className="notification">
          <img
            src={notification.avatar}
            alt="avatar"
            className="notification-avatar"
          />
          <p>{notification.name} baru saja bergabung</p>
        </div>
      )}
      <header>
        <button className="header" onClick={handleButtonClick}>
          <div className="blinking-text">
            <p>
              Hubungi kami untuk mendapatkan code <span className="large-text">$100</span> dengan mengirim pesan
              hari ini melalui WhatsApp
            </p>
            <h1>Jumlah terbatas!</h1>
          </div>
        </button>
      </header>
    </div>
  );
};

export default Header;
