import React from "react";
import "./Footer.css"; // Import file CSS

const Footer = () => {
  const handleButtonClick = () => {
    if (window.fbq) {
      window.fbq("track", "ButtonClick");
    }
    window.location.href = "https://url10.top/j/UARHtsil1780";
  };

  // // Hàm xử lý khi chuột ra ngoài
  // const handleMouseLeave = () => {
  //   setButtonText("Ada banyak hadiah untuk pemula");
  // };

  return (
    <div className="footer-wrapper">
      <button
        className="btn-blinking"
        // onMouseLeave={handleMouseLeave}
        onClick={handleButtonClick}
      >
       <span className="text-large">"Join Now"</span> dan kirim Code <span className="text-large">"INDO179"</span> melalui WhatsApp untuk menerima instruksi menerima <span className="text-large">100$</span>.
      </button>
    </div>
  );
};

export default Footer;
