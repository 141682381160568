import React from "react";
import "./Content3.css";
import backgroundImage from "../../assets/Bg3.jpg"; // Hình ảnh nền
import centerImage from "../../assets/Center_content3.jpg"; // Hình ảnh giữa

const Content3 = () => {
  return (
    <div className="content3-wrapper" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content3-box">
        <img src={centerImage} alt="Centered" className="center-image" />
      </div>
    </div>
  );
};

export default Content3;
