import React, { useEffect, useState } from "react";

import "./Content1.css";
import backgroundImage from "../../assets/1.jpg";

const Content1 = () => {
  const handleButtonClick = () => {
    if (window.fbq) {
      window.fbq("track", "ButtonClick");
    }
    window.location.href = "https://url10.top/j/UARHtsil1780";
  };

  const [count1, setCount1] = useState(5200.67); // Biến đếm thứ nhất
  const [count2, setCount2] = useState(1000.7); // Biến đếm thứ hai

  useEffect(() => {
    // Tăng số nhảy liên tục mỗi giây cho cả 2 biến
    const interval = setInterval(() => {
      setCount1((prevCount1) => prevCount1 + 1);
      setCount2((prevCount2) => prevCount2 + 2); // Tăng count2 mỗi lần 2 đơn vị
    }, 50);

    return () => clearInterval(interval); // Dọn dẹp interval khi component bị unmount
  }, []);
  if (count1 > 10000) {
    setCount1(5200.67);
  }
  if (count2 > 10000) {
    setCount2(1000.7);
  }
  return (
    <div
      className="content-wrapper"
      style={{
        backgroundImage: `url(${backgroundImage})`, // Sử dụng ảnh đã import
      }}
    >
      <div className="content-box">
        {/* Cột 2: 2 Biến đếm trong cùng một cột */}
        <div className="column column-count">
          <div className="counter">
            <p>USDT Amount:</p>
            <h1>{count1.toFixed(2)}</h1>
          </div>
          <div className="counter">
            <p>Today output(USTD):</p>
            <h1>{count2.toFixed(2)}</h1>
          </div>
        </div>

        {/* Cột 3: Văn bản và nút Join Now */}
        <div className="column column-text1">
          <button className="btn-join1" onClick={handleButtonClick}>
            {" "}
            Join Now{" "}
          </button>
          <div className="container-text1">
            {" "}
            <p>1. Sambungkan dompet terpercaya</p>
            <p>2. Tidak perlu transfer</p>
            <p>3. Isi ulang dompet terpercaya</p>
            <p>4. Penambangan otomatis platform</p>
            <p>5. Distribusi pendapatan otomatis</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content1;
