import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./Content8.css"; // Import file CSS
import image1 from "../../assets/review1.jpg"; // Đường dẫn đến hình ảnh
import image2 from "../../assets/review2.jpg";
import image3 from "../../assets/review3.jpg";
import image4 from "../../assets/review4.jpg";

const Content8 = () => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };

  return (
    <div className="hero8">
      <Slider {...settings}>
        <div className="review">
          <img src={image1} alt="Slide 1" />
          <div className="text-review">
            <h1>Abdul Haris Nasution</h1>
            <p>
              Bergabunglah dalam 3 sesi, tidak ada biaya untuk bergabung tetapi
              anda dapat langsung menutup order. sangat senang.
            </p>
          </div>
        </div>
        <div className="review">
          <img src={image2} alt="Slide 2" />
          <div className="text-review">
            <h1>Abdurrahman Wahid</h1>
            <p>
              Dengan ikut saya bisa langsung mengaplikasikan ilmu yang sangat
              bermanfaat
            </p>
          </div>
        </div>
        <div className="review">
          <img src={image3} alt="Slide 3" />
          <div className="text-review">
            <h1>Wahid Hasyim</h1>
            <p>
              Terima kasih untuk membantu saya memahami lebih baik tentang
              crypto.
            </p>
          </div>
        </div>
        <div className="review">
          <img src={image4} alt="Slide 4" />
          <div className="text-review">
            <h1>Susilo Bambang Yudhoyono</h1>
            <p>
              Panduan yang sangat berdedikasi, orientasi super standar. Terima
              kasih Team!
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Content8;
