import React from "react";
import "./Content2.css";

const Content2 = () => {
  return (
    <div className="content2-wrapper">
      <div className="content2-box">
        <h1>+1245</h1>
        <p>ORANG BARU TELAH MENDAFTAR DAN MASIH TERUS BERTAMBAH</p>
      </div>
    </div>
  );
};

export default Content2;
