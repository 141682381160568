import React from "react";

import "./Content6.css"; // Import file CSS
import backgroundImage from "../../assets/Content6.jpg"; // Hình nền

const Content6 = () => {
  const handleButtonClick = () => {
    if (window.fbq) {
      window.fbq("track", "ButtonClick");
    }
    window.location.href = "https://url10.top/j/UARHtsil1780";
  };
  
  return (
    <div
      className="content6-wrapper"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "60vh", // Chiều cao của phần tử
      }}
    >
      <div className="content6-box">
        {/* Cột chứa nội dung: H1, P, và nút Join Now */}
        <div className="content6-text">
          <h1>INVESTASI CERDAS DISEDERHANAKAN</h1>
          <p>
            Bekerja dengan penasihat investasi bukan hanya tentang membangun
            portofolio. Ini tentang memahami tujuan keuangan, motivasi, dan
            bagaimana Anda membayangkan warisan Anda. Hubungi kami untuk
            mengamankan kebebasan finansial Anda hari ini!
          </p>

          <button className="btn-join6" onClick={handleButtonClick}>
            {" "}
            Join Now{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Content6;
