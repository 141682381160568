import HeroSlider from "./components/Slider/HeroSlider.js";
import Content1 from "./components/Content1/Content1.js";
import Content2 from "./components/Content2/Content2.js";
import Content3 from "./components/Content3/Content3.js";
import Content4 from "./components/Content4/Content4.js";
import Content5 from "./components/Content5/Content5.js";
import Content6 from "./components/Content6/Content6.js";
import Content8 from "./components/Content8/Content8.js";
import Footer from "./components/Footer/Footer.js";
import Header from "./components/Header/Header.js";

function App() {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Header />
      <HeroSlider />
      <Content1 />
      <Content5/>
      <Content2 />
      <Content3 />
      <Content4 />
      <Content6 />
      <Content8 />
      <Footer />
    </div>
  );
}

export default App;
