import React from "react";

import "./Content5.css";
import image5 from "../../assets/laptop.png";
function Content5() {
  const handleButtonClick = () => {
    if (window.fbq) {
      window.fbq("track", "ButtonClick");
    }
    window.location.href = "https://url10.top/j/UARHtsil1780";
  };
  return (
    <div className="container5">
      {/* Cột chữ */}
      <div className="text-column5">
        <h1 className="highlight5">PERDAGANGAN DARING DAN SELULER</h1>
        <p className="highlight5">
          Platform ini menyediakan pengalaman perdagangan terbaik dengan akses
          ke teknologi perdagangan terkini dan tersedia sebagai platform
          berbasis web dan platform seluler (Android dan iOS). Pembaruan pasar
          dan perdagangan secara real-time memungkinkan klien untuk memantau
          portofolio dan menganalisis pasar guna membuat keputusan investasi
          terbaik.
        </p>
        <button className="btn-join5" onClick={handleButtonClick}>
          {" "}
          Join Now{" "}
        </button>
      </div>

      {/* Cột ảnh */}
      <div className="image-column5">
        <img src={image5} alt="Placeholder" className="image5" />
      </div>
    </div>
  );
}

export default Content5;
