import React from "react";
import "./Content4.css";
import image from "../../assets/3.jpg"; // Hình ảnh làm background
import phoneImage from "../../assets/Person_CT4.png"; // Ảnh mới thêm vào

const Content4 = () => {
  const handleButtonClick = () => {
    if (window.fbq) {
      window.fbq("track", "ButtonClick");
    }
    window.location.href = "https://url10.top/j/UARHtsil1780";
  };
  return (
    <div
      className="content4-wrapper"
      style={{
        backgroundImage: `url(${image})`, // Đặt ảnh nền trực tiếp từ import
      }}
    >
      {/* Thêm ảnh cho góc phải của content4-wrapper */}
      <img src={phoneImage} alt="PhoneImage" className="phone-image" />

      <div className="content4-box">
        {/* Đoạn văn 1: Góc phần tư thứ nhất */}
        <div className="text-1">
          <h1>MENERIMA SINYAL PASAR</h1>
          <p>
            Melalui konsultasi langsung, kami akan memberikan sinyal paling awal
            dari pasar, membantu Anda mengontrol strategi investasi Anda dengan
            lebih baik dan membuat keputusan Buying/Selling pada waktu yang
            tepat.
          </p>
          <button className="btn-join4" onClick={handleButtonClick}>
            {" "}
            Join Now{" "}
          </button>
        </div>

        {/* Đoạn văn 2: Góc phần tư thứ ba */}
        <div className="text-2">
          <h1>PENGALAMAN MENEMPATKAN PESANAN</h1>
          <p>
            Keterampilan penting seperti cara melakukan pemesanan, memilih
            platform, dan memikirkan harga akan membantu Anda memahami dengan
            kuat detail dalam semua keputusan investasi Anda.
          </p>
          <button className="btn-join1" onClick={handleButtonClick}>
            {" "}
            Join Now{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Content4;
